.rdrCalendarWrapper {
    width: 100%;
    height: 100%;    
}

.rdrCalendarWrapper .rdrMonths .rdrMonth {
    width: 100%!important;
    height: 100%!important;   
}

.rdrDateDisplayWrapper {
    display: none;
}

.rdrMonthName {
    display: none;
}

#conveyance-drawer {
    width: 0;
    height: 0;
    max-height: 550px;
    z-index: 1000;
    position: fixed;
    bottom: 0px;
    background-color: white;
    /* border: 0.1px solid; */
    /* top-left | top-right | bottom-right | bottom-left */
    border-radius: 6px 6px 0px 0px;
    overflow-x: hidden;
    transition: 0.8s;
    /* padding-top: 60px; */
  }
  
.drawer .drawerHeader { 
 
}
  
.drawer .drawerClosebtn {

}

.drawer .drawerCenterText {

}

#booking-page-button {
    height: 80%;
    background-color: #FF6400!important;
    color: #FFFFFF!important;
}  
 
#booking-page-button:hover {
    background-color: #FF6400!important;
    color: #FFFFFF!important;
    border: none
}

.search-result-page-dropdown .Dropdown-control {
    border-radius: 0.25rem; 
    border-width: 1px; 
    border-color: #FF6400; 
    /* border: 1px solid #FF6400!important */
}

#add-conveyance-info-drawer {
    width: 0;
    height: 0;
    z-index: 1000;
    position: fixed;
    bottom: 0px;
    background-color: white;
    /* border: 0.1px solid; */
    /* top-left | top-right | bottom-right | bottom-left */
    border-radius: 6px 6px 0px 0px;
    overflow-x: hidden;
    transition: 0.8s;
    /* padding-top: 60px; */
}


#dynamic_rule_conveyanceinfo-switch1.ant-switch {
    background-color: #E9ECEF;
}

#dynamic_rule_conveyanceinfo-switch1.ant-switch.ant-switch-checked {
    background-color: #FF6D00;
}

 




 
 
  
 
  