#validate_other_campinginfo-switch1.ant-switch {
    background-color: #E9ECEF;
}

#validate_other_campinginfo-switch1.ant-switch.ant-switch-checked {
    background-color: #FF6D00;
}

#validate_other_campinginfo-switch2.ant-switch {
    background-color: #E9ECEF;
}

#validate_other_campinginfo-switch2.ant-switch.ant-switch-checked {
    background-color: #FF6D00;
}

.ant-btn.ant-btn-default.add-submit-button {
    background-color: #000;
    color: white;
    border: none;
}

