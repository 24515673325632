@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-gray-800;
  }
  button,
  input,
  textarea,
  select {
    @apply focus:outline-none;
  }
}

.swiper-container {
  padding-bottom: 20px;
}

.swiper-pagination {
  bottom: -5px !important;
  z-index: 20;
}

.swiper-pagination-bullet {
  opacity: 0.5;
  background-color: black;
}

#gray_bullet .swiper-pagination-bullet {
  opacity: 0.5;
  background-color: black;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  width: 20px;
  border-radius: 40px;
  transition: opacity 0.25s, background-color 0.25s, width 0.25s;
}

#gray_bullet .swiper-pagination-bullet-active {
  opacity: 1;
  width: 20px;
  border-radius: 40px;
  transition: opacity 0.25s, background-color 0.25s, width 0.25s;
}

@layer components {
  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .avatar {
    @apply inline-block object-cover rounded-full;
  }
  .avatar-default {
    @apply inline-block align-middle rounded-full overflow-hidden bg-gray-100;
  }
  .button {
    @apply px-4 h-12  font-bold;
  }
  .card {
    @apply flex flex-col  border border-gray-200 divide-y divide-gray-200 overflow-hidden shadow;
  }
  .checkbox {
    @apply wh-4  text-gray-900
           focus:outline-none focus:ring-0;
  }
  .dropdown {
    @apply relative w-max;
  }
  .dropdown-view {
    @apply absolute mt-2 w-max shadow-lg ring-1 ring-black ring-opacity-5 bg-white z-10
           focus:outline-none transition transform origin-top;
  }

  h1 {
    @apply text-24 font-bold;
  }
  h2 {
    @apply text-18 font-bold;
  }
  h3 {
    @apply text-16 font-bold;
  }
  h4 {
    @apply text-14 font-bold;
  }

  .label {
    @apply text-sm text-gray-800;
  }
  .label-col {
    @apply flex flex-col space-y-1;
  }
  .label-row {
    @apply flex items-center space-x-2;
  }
  .menu {
    @apply flex flex-col py-1;
  }
  .menu-item {
    @apply px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-800;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .pagination {
    @apply flex justify-between items-center px-6 py-3;
  }
  .pagination-label {
    @apply text-sm text-gray-700;
  }
  .pagination-nav {
    @apply flex shadow-sm -space-x-px;
  }
  .pagination-nav-button {
    @apply grid place-items-center p-2 min-w-10 border text-sm font-medium;
  }
  .radio {
    @apply wh-4 text-brand-1
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1;
  }

  .img {
    @apply w-full h-full object-cover;
  }

  .bottom-padding {
    @apply pb-20;
  }
  .side-padding {
    @apply px-4;
  }

  .select {
    @apply h-12  border-none rounded-none placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .sidebar {
    @apply flex flex-col w-64 border-r border-gray-200;
  }
  .sidebar-title {
    @apply flex items-center px-4 h-16 font-bold text-2xl text-gray-800;
  }
  .sidebar-menu {
    @apply flex-grow flex flex-col space-y-1 p-2 overflow-y-auto;
  }
  .sidebar-menu-item {
    @apply p-2 rounded-none text-sm font-medium flex items-center;
  }
  .table {
    @apply divide-y divide-gray-200;
  }
  .table-head {
    @apply bg-gray-50;
  }
  .table-body {
    @apply divide-y divide-gray-200;
  }
  .table-th {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }
  .table-td {
    @apply px-6 py-4 max-w-xs text-left text-sm text-gray-500 truncate;
  }
  .textarea {
    @apply px-4 py-2 border border-gray-200 rounded-none placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }

  .text-error {
    @apply text-red-500;
  }

  .textfield {
    @apply px-4 h-12 border border-gray-200 rounded-none placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .toggle {
    @apply relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full
           cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1
           transition-colors ease-in-out duration-200;
  }
  .toggle-slider {
    @apply pointer-events-none inline-block w-5 h-5 rounded-full bg-white shadow ring-0
           transition ease-in-out duration-200 transform;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

@media screen and (max-width: 768px) {
  .mobile-button {
    position: relative;
  }
  .mobile-button::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200%;
    height: 200%;
  }
}
