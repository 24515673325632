.rdrCalendarWrapper {
    width: 100%;
    height: 100%;    
}

.rdrCalendarWrapper .rdrMonths .rdrMonth {
    width: 100%!important;
    height: 100%!important;   
}

.rdrDateDisplayWrapper {
    display: none;
}

.rdrMonthName {
    display: none;
}

#general-drawer {
    width: 0;
    height: 0;
    max-height: 950px;
    z-index: 1000;
    position: fixed;
    bottom: 0px;
    background-color: white;
    /* border: 0.1px solid; */
    /* top-left | top-right | bottom-right | bottom-left */
    border-radius: 6px 6px 0px 0px;
    overflow-x: hidden;
    transition: 0.8s;
    /* padding-top: 60px; */
 
  }
  
.drawer .drawerHeader { 
 
}
  
.drawer .drawerClosebtn {

}

.drawer .drawerCenterText {

}

#booking-page-button {
    height: 80%;
    background-color: #FF6400!important;
    color: #FFFFFF!important;
}  
 
#booking-page-button:hover {
    background-color: #FF6400!important;
    color: #FFFFFF!important;
    border: none
}

.search-result-page-dropdown .Dropdown-control {
    border-radius: 0.25rem; 
    border-width: 1px; 
    border-color: #FF6400; 
    /* border: 1px solid #FF6400!important */
}

@keyframes blink {
   
    50% { color: transparent }
}
.loading-dot { animation: blink 1s infinite }
.loading-dot:nth-child(2) { animation-delay: 250ms }
.loading-dot:nth-child(3) { animation-delay: 500ms }
 
.loading-dots-type1 {
height: 5px;
width: 5px;
border-radius: 50%;
animation: dot-keyframes1 1s infinite ;
}
@keyframes dot-keyframes1 {
    0%  {box-shadow: 10px 0 #000, -10px 0 #0002;background: #000 }
    33% {box-shadow: 10px 0 #000, -10px 0 #0002;background: #0002}
    66% {box-shadow: 10px 0 #0002,-10px 0 #000; background: #0002}
    100%{box-shadow: 10px 0 #0002,-10px 0 #000; background: #000 }
}

  
 
  